import { Card, CardHeader, Typography, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { IoIosAddCircleOutline } from "react-icons/io";
import { useSelector } from "react-redux";
import { BsListCheck } from "react-icons/bs";
import WaitingListForm from "../../form";
import { setDialog } from "../../../../app/slices/waitingListSlice";

const WaitingListHeader = () => {
  const _WaitingList = useSelector(
    (state) => state.waitingList.waitingList.dialog
  );
  const dispatch = useDispatch();

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            قائمة الانتظار
          </Typography>
        }
        avatar={<BsListCheck size={20} />}
        action={
          <Button
            variant="contained"
            color="primary"
            size="medium"
            startIcon={<IoIosAddCircleOutline />}
            onClick={() => dispatch(setDialog())}
          >
            إضافة
          </Button>
        }
      />
      {_WaitingList && <WaitingListForm />}
    </Card>
  );
};

export default WaitingListHeader;
