import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import Column from "./Column";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  setSearchBody,
  waitingList,
} from "../../../../app/slices/waitingListSlice";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import TimeDialog from "./timeDialog";
import {
  Appointment,
  setDialog,
  setDoctorsAppointmentsSearchBody,
} from "../../../../app/slices/appointmentSlice";
import { MdModeEdit } from "react-icons/md";
import AppointmentForm from "../../../appointment/form";
import { IoIosAddCircleOutline } from "react-icons/io";
import { useTheme } from "@emotion/react";
import { ChangeAppointmentStatus } from "../../../appointment/component/ChangeAppointmentStatus";
import { FaCircleInfo, FaComputer, FaUser } from "react-icons/fa6";
import { BiUserCircle } from "react-icons/bi";

const Container = styled("div")`
  display: flex;
  background-color: ${(props) =>
    props.isDraggingOver ? "#639ee2" : "inherit"};
`;

const WaitingListData = () => {
  const dispatch = useDispatch();

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const _Appointments = useSelector(
    (state) => state.appointment.doctorsAppointments
  );
  const _Appointment = useSelector((state) => state.appointment.appointment);
  const _WaitingListTypes = useSelector(
    (state) => state.waitingList.waitingListData
  );
  const loading = useSelector((state) => state.waitingList.loading);
  const _DoctorsAppointmentsSearchBodyLoading = useSelector(
    (state) => state.appointment.loading
  );
  const _SearchBody = useSelector((state) => state.waitingList.searchBody);
  const _WaitingList = useSelector(
    (state) => state.waitingList.timeData.dialog
  );
  const _DoctorsAppointmentsSearchBody = useSelector(
    (state) => state.appointment.doctorsAppointmentsSearchBody
  );

  const [selectedUserId, setSelectedUserId] = useState(null);
  const [starter, setStarter] = useState([]);

  useEffect(() => {
    setStarter(_WaitingListTypes?.data);
  }, [_WaitingListTypes.data]);

  useEffect(() => {
    dispatch(
      setDoctorsAppointmentsSearchBody({
        start_date: _SearchBody.date,
        end_date: _SearchBody.date,
        skip: 0,
        take: 1000,
      })
    );
    dispatch(waitingList.getAll(_SearchBody));
    dispatch(
      Appointment.getAllDoctorsAppointment({
        start_date: _SearchBody.date,
        end_date: _SearchBody.date,
        skip: 0,
        take: 1000,
      })
    );
  }, [_SearchBody, dispatch]);

  const onDragEnd = ({ destination, source, draggableId, type }) => {
    if (!destination) return;
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const start = starter.columns[source.droppableId];
    const end = starter.columns[destination.droppableId];

    if (type === "column") {
      const newOrder = [...starter.columnOrder];
      newOrder.splice(source.index, 1);
      newOrder.splice(destination.index, 0, draggableId);
      setStarter({
        ...starter,
        columnOrder: newOrder,
      });
      return;
    }

    const startPatientIds = [...start.patient_id];
    const endPatientIds = [...end.patient_id];

    startPatientIds.splice(source.index, 1);
    endPatientIds.splice(destination.index, 0, draggableId);

    const waitingListObject = Object.values(starter.patients).find(
      (patient) => patient.patient_id === draggableId
    );

    const match = draggableId.match(/\d+/);
    const destinationColumnId = destination.droppableId.match(/\d+/);
    const sourceColumnId = source.droppableId.match(/\d+/);
    const isForward = destinationColumnId >= sourceColumnId ? true : false;

    switch (destination.droppableId) {
      case "column-1":
        return isForward
          ? dispatch(
              waitingList.update(
                parseInt(match[0], 10),
                {
                  current_status: "waiting_treatment",
                  date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
                },
                _SearchBody
              )
            )
          : waitingListObject.waiting_treatment
          ? dispatch(
              waitingList.update(
                parseInt(match[0], 10),
                {
                  current_status: "waiting_treatment",
                },
                _SearchBody
              )
            )
          : dispatch(
              waitingList.update(
                parseInt(match[0], 10),
                {
                  current_status: "waiting_treatment",
                  date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
                },
                _SearchBody
              )
            );
      case "column-2":
        return isForward
          ? dispatch(
              waitingList.update(
                parseInt(match[0], 10),
                {
                  current_status: "in_treatment",
                  date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
                },
                _SearchBody
              )
            )
          : waitingListObject.in_treatment
          ? dispatch(
              waitingList.update(
                parseInt(match[0], 10),
                {
                  current_status: "in_treatment",
                },
                _SearchBody
              )
            )
          : dispatch(
              waitingList.update(
                parseInt(match[0], 10),
                {
                  current_status: "in_treatment",
                  date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
                },
                _SearchBody
              )
            );
      case "column-3":
        return isForward
          ? dispatch(
              waitingList.update(
                parseInt(match[0], 10),
                {
                  current_status: "waiting_xray",
                  date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
                },
                _SearchBody
              )
            )
          : waitingListObject.waiting_xray
          ? dispatch(
              waitingList.update(
                parseInt(match[0], 10),
                {
                  current_status: "waiting_xray",
                },
                _SearchBody
              )
            )
          : dispatch(
              waitingList.update(
                parseInt(match[0], 10),
                {
                  current_status: "waiting_xray",
                  date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
                },
                _SearchBody
              )
            );
      case "column-4":
        return isForward
          ? dispatch(
              waitingList.update(
                parseInt(match[0], 10),
                {
                  current_status: "in_xray",
                  date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
                },
                _SearchBody
              )
            )
          : waitingListObject.in_xray
          ? dispatch(
              waitingList.update(
                parseInt(match[0], 10),
                {
                  current_status: "in_xray",
                },
                _SearchBody
              )
            )
          : dispatch(
              waitingList.update(
                parseInt(match[0], 10),
                {
                  current_status: "in_xray",
                  date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
                },
                _SearchBody
              )
            );
      case "column-5":
        return isForward
          ? dispatch(
              waitingList.update(
                parseInt(match[0], 10),
                {
                  current_status: "leaving",
                  date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
                },
                _SearchBody
              )
            )
          : waitingListObject.leaving
          ? dispatch(
              waitingList.update(
                parseInt(match[0], 10),
                {
                  current_status: "leaving",
                },
                _SearchBody
              )
            )
          : dispatch(
              waitingList.update(
                parseInt(match[0], 10),
                {
                  current_status: "leaving",
                  date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
                },
                _SearchBody
              )
            );

      default:
        return null;
    }
  };

  const changeAppointmentStatus = (id, item) => {
    dispatch(
      Appointment.changeStatus(
        { appointment_id: id, status: item.name },
        _DoctorsAppointmentsSearchBody,
        true
      )
    );
  };

  return (
    <Card sx={{ minHeight: "80vh", overflow: "auto", width: "100%" }}>
      {_WaitingList && <TimeDialog />}
      {_Appointment.dialog ? (
        <AppointmentForm doctorsAppointments={true} userId={selectedUserId} />
      ) : null}
      {/* {(loading || _DoctorsAppointmentsSearchBodyLoading) && <LinearProgress />} */}
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                onClick={() => {
                  dispatch(
                    setSearchBody({
                      date: dayjs(_SearchBody.date)
                        .subtract(1, "day")
                        .format("YYYY-MM-DD"),
                    })
                  );
                }}
              >
                اليوم السابق
              </Button>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="YYYY-MM-DD"
                  label="التاريخ"
                  value={_SearchBody.date}
                  onChange={(e, newValue) => {
                    dispatch(
                      setSearchBody({
                        date: dayjs(e).format("YYYY-MM-DD"),
                      })
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      size="small"
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "يوم/شهر/سنة",
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
              <Button
                variant="contained"
                onClick={() => {
                  dispatch(
                    setSearchBody({
                      date: dayjs(_SearchBody.date)
                        .add(1, "day")
                        .format("YYYY-MM-DD"),
                    })
                  );
                }}
              >
                اليوم التالي
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            {!_WaitingListTypes.data.patients ? null : (
              <Box>
                {/* <Stack
                  direction="row"
                  spacing={0}
                  alignItems="center"
                  // justifyContent="space-around"
                  // marginRight={31}
                >
                  <Typography
                    variant="h3"
                    textAlign="center"
                    sx={{
                      borderRight: "1px solid #D3D3D3",
                      width: "40%",
                      margin: "10px 0",
                    }}
                  >
                    الفحص
                  </Typography>
                  <Typography
                    variant="h3"
                    textAlign="center"
                    sx={{
                      borderRight: "1px solid #D3D3D3",
                      width: "40%",
                      margin: "10px 0",
                    }}
                  >
                    الاشعة
                  </Typography>
                  <Typography
                    variant="h3"
                    textAlign="center"
                    sx={{ width: "20%", margin: "10px 0" }}
                  >
                    المغادرة
                  </Typography>
                </Stack> */}
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable
                    droppableId="all-column"
                    type="column"
                    direction="horizontal"
                    isDropDisabled
                  >
                    {(provided, snapshot) => (
                      <Container
                        isDraggingOver={snapshot.isDraggingOver}
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                          borderRadius: 3,
                        }}
                      >
                        <Grid container spacing={2}>
                          {starter.columnOrder?.map((columnId, index) => {
                            const column = starter?.columns[columnId];
                            const patients = column.patient_id?.map(
                              (patient_id) => starter.patients[patient_id]
                            );
                            return (
                              <Grid key={column.id} item xs={6} md={2.4}>
                                <Column
                                  index={index}
                                  key={column.id}
                                  column={column}
                                  patients={patients}
                                />
                              </Grid>
                            );
                          })}
                          {provided.placeholder}
                        </Grid>
                      </Container>
                    )}
                  </Droppable>
                </DragDropContext>
              </Box>
            )}
          </Grid>
          <Grid item xs={12}>
            {_Appointments.map((doctor, index) => (
              <Card key={index} sx={{ my: 1 }}>
                <CardHeader
                  avatar={<BiUserCircle fontSize={28}  />}
                  title={
                    <Typography variant="h4">{doctor.user_name}</Typography>
                  }
                  action={
                    <Button
                      variant="contained"
                      color="primary"
                      size="medium"
                      startIcon={<IoIosAddCircleOutline />}
                      onClick={() => {
                        setSelectedUserId(doctor.user_id);
                        dispatch(setDialog());
                      }}
                    >
                      إضافة موعد
                    </Button>
                  }
                />
                <CardContent>
                  <Divider />
                  <Box
                    sx={{
                      display: smallScreen ? null : "flex",
                      flexWrap: smallScreen ? null : "wrap",
                    }}
                  >
                    {doctor.appointments.map((appointment, index) => (
                      <Card
                        key={index}
                        sx={{ m: 1, width: smallScreen ? "100%" : "300px" }}
                      >
                        <CardHeader
                          title={
                            <Stack spacing={2}>
                              <Typography variant="h4">
                                {appointment.patient_name}
                              </Typography>
                              <Typography variant="h4">
                                {appointment.patient_mobile_1}
                              </Typography>
                            </Stack>
                          }
                          action={
                            <IconButton
                              variant="contained"
                              color="warning"
                              onClick={() =>
                                dispatch(Appointment.getById(appointment.id))
                              }
                            >
                              <MdModeEdit />
                            </IconButton>
                          }
                        />
                        <CardContent>
                          <Stack direction="row" justifyContent="space-between">
                            <ChangeAppointmentStatus
                              name={appointment.status}
                              status={appointment.status}
                              onChangeStatus={(item) => {
                                changeAppointmentStatus(appointment.id, item);
                              }}
                            />
                            {/* <Chip
                              variant="contained"
                              size="small"
                              color={
                                appointment.channel === 2 ? "info" : "default"
                              }
                              label={
                                appointment.channel === 2
                                  ? "حجز الكتروني"
                                  : "حجز من النظام"
                              }
                            /> */}
                            {appointment.channel === 1 ? (
                              <FaComputer size={25} color="#C2EAFC" />
                            ) : (
                              <FaUser size={25} color="#81C784" />
                            )}
                          </Stack>
                          <Typography sx={{ my: 1 }}>
                            {appointment.operation_type_name}
                          </Typography>
                          <Typography sx={{ my: 1 }}>
                            {dayjs(appointment.date).format(
                              "YYYY-MM-DD / hh:mm a"
                            )}
                          </Typography>
                          {appointment.note ? (
                            <Tooltip
                              title={
                                <Typography variant="h4">
                                  {appointment.note}
                                </Typography>
                              }
                              followCursor
                            >
                              <Typography align="center">
                                <FaCircleInfo size={20} />
                              </Typography>
                            </Tooltip>
                          ) : null}
                        </CardContent>
                      </Card>
                    ))}
                  </Box>
                </CardContent>
              </Card>
            ))}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default WaitingListData;
