import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import appSlice from "./slices/appSlice";

import notificationSlice from "./slices/notificationSlice";
import userSlice from "./slices/userSlice";
import provincesSlice from "./slices/provincesSlice";
import sectionSlice from "./slices/sectionSlice";
import patientSlice from "./slices/patientSlice";
import caseTypeSlice from "./slices/caseTypeSlice";
import appointmentSlice from "./slices/appointmentSlice";
import expenseSlice from "./slices/expenseSlice";
import expenseTypesSlice from "./slices/expenseTypesSlice";
import operationSlice from "./slices/operationSlice";
import stockSlice from "./slices/stockSlice";
import stockMovementSlice from "./slices/stockMovementSlice";
import statisticSlice from "./slices/statisticSlice";
import reportsSlice from "./slices/reportsSlice";
import paymentSlice from "./slices/paymentsSlice";
import toothSlice from "./slices/toothSlice";
import treatmentPlanSlice from "./slices/treatmentPlanSlice";
import operationTypeSlice from "./slices/operationTypeSlice";
import clearanceSlice from "./slices/clearance";
import doctorExpensesSlice from "./slices/doctorExpensesSlice";
import xraySlice from "./slices/xraySlice";
import waitingListSlice from "./slices/waitingListSlice";
import dentalImplantSlice from "./slices/dentalImplantSlice";

export const store = configureStore({
  reducer: {
    app: appSlice,
    notification: notificationSlice,
    user: userSlice,
    provinces: provincesSlice,
    section: sectionSlice,
    patient: patientSlice,
    caseType: caseTypeSlice,
    appointment: appointmentSlice,
    expense: expenseSlice,
    expenseTypes: expenseTypesSlice,
    operation: operationSlice,
    stock: stockSlice,
    stockMovement: stockMovementSlice,
    statistic: statisticSlice,
    reports: reportsSlice,
    payment: paymentSlice,
    tooth: toothSlice,
    treatmentPlan: treatmentPlanSlice,
    operationType: operationTypeSlice,
    clearance: clearanceSlice,
    doctorExpense: doctorExpensesSlice,
    xray: xraySlice,
    waitingList: waitingListSlice,
    dentalImplant: dentalImplantSlice,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

setupListeners(store.dispatch);
