import user from "./endpoints/user";
import provinces from "./endpoints/provinces";
import section from "./endpoints/section";
import patient from "./endpoints/patient";
import caseType from "./endpoints/caseType";
import appointment from "./endpoints/appointment";
import expense from "./endpoints/expense";
import expenseTypes from "./endpoints/expenseTypes";
import operation from "./endpoints/operation";
import stock from "./endpoints/stock";
import stockMovement from "./endpoints/stockMovment";
import statistic from "./endpoints/statistic";
import reports from "./endpoints/reports";
import payment from "./endpoints/payments";
import teeth from "./endpoints/teeth";
import treatmentPlan from "./endpoints/treatmentPlan";
import operationType from "./endpoints/operationType";
import clearance from "./endpoints/clearance";
import doctorExpense from "./endpoints/doctorExpenses";
import xray from "./endpoints/xray";
import waitingList from "./endpoints/waitingList";
import dentalImplant from "./endpoints/dentalImplant";

const repositories = {
  user,
  provinces,
  section,
  patient,
  caseType,
  appointment,
  expense,
  expenseTypes,
  operation,
  operationType,
  stock,
  stockMovement,
  statistic,
  reports,
  payment,
  teeth,
  treatmentPlan,
  clearance,
  doctorExpense,
  xray,
  waitingList,
  dentalImplant,
};

export const factory = {
  get: (name) => repositories[name],
};
