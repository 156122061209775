import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./notificationSlice";
import { factory } from "../../api/apiFactory";
const operationTypeApi = factory.get("operationType");
const initialState = {
  loading: false,
  searchBody: {},
  dialog: false,
  operationType: {
    dialog: false,
    form: {
      id: null,
      name: "",
      section_id: null,
      price: 0,
      discount: 0,
      is_treatment_plan: false,
      is_all_teeth: false,
      note: "",
      deduction: 0,
    },
  },
  operationTypes: {
    data: [],
    total: 0,
  },
  treatmentPlan: [],
};

export const operationTypeSlice = createSlice({
  name: "operationType",
  initialState,
  reducers: {
    setDialog: (state, action) => {
      state.dialog = !state.dialog;
    },
    setOperationDialog: (state, action) => {
      state.operationType.dialog = !state.operationType.dialog;
    },
    setDataTable: (state, { payload }) => {
      state.operationTypes.data = payload.data.data;
      state.operationTypes.total = payload.data.total;
    },
    setById: (state, { payload }) => {
      state.operationType.form = payload;
    },
    setLoading: (state, action) => {
      state.loading = !state.loading;
    },
    resetForm: (state, action) => {
      state.operationType.form = initialState.operationType.form;
    },
    setSearchBody: (state, { payload }) => {
      state.searchBody = payload;
    },
  },
});

export const {
  setLoading,
  setOperationDialog,
  setById,
  setDataTable,
  resetForm,
  setSearchBody,
  setDatalist,
  setDialog,
} = operationTypeSlice.actions;

export default operationTypeSlice.reducer;

//axios
const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("operationType"));
    const res = await operationTypeApi.getAll(params);
    dispatch(setDataTable(res));
    dispatch(setLoading("operationType"));
  } catch (err) {
    dispatch(setLoading("operationType"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const deleteById = (id, _SearchBody) => async (dispatch) => {
  try {
    await operationTypeApi.delete(id);
    dispatch(
      showNotification({
        message: "تم الحذف بنجاح",
        type: "success",
      })
    );
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("operationType"));
    const res = await operationTypeApi.getById(id);
    dispatch(setById(res.data.data[0]));

    dispatch(setOperationDialog());
    dispatch(setLoading("operationType"));
  } catch (err) {
    dispatch(setLoading("operationType"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const create = (data, _SearchBody) => async (dispatch) => {
  try {
    await operationTypeApi.create(data);
    dispatch(
      showNotification({
        message: "تمت الاضافة  بنجاح",
        type: "success",
      })
    );
    dispatch(setOperationDialog());
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const update = (id, data, _SearchBody) => async (dispatch) => {
  try {
    await operationTypeApi.update(id, data);
    dispatch(
      showNotification({
        message: "تم التعديل  بنجاح",
        type: "success",
      })
    );
    dispatch(setOperationDialog());
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

export const OperationType = {
  getAll,
  deleteById,
  getById,
  create,
  update,
};
