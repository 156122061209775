import dayjs from "dayjs";
import {
  createSlice
} from "@reduxjs/toolkit";
import {
  showNotification
} from "./notificationSlice";
import {
  factory
} from "../../api/apiFactory";
const userApi = factory.get("user");

const initialState = {
  searchBody: {},
  user: {
    loading: false,
    dialog: false,
    form: {
      id: "",
      full_name: "",
      user_name: "",
      password: "",
      email: "",
      job_title: "",
      birth_date: dayjs().format('YYYY-MM-DD'),
    },
  },
  users: {
    loading: false,
    data: [],
    total: 0,
  },
  usersList: {
    loading: false,
    data: [],
    total: 0,
  },
  doctorList: [],
  userInfoLoading: false,
  userInfo: {
    id: null,
    email: "",
    birth_date: "",
    full_name: "",
    job_title: "",
    role: "",
    user_name: "",
    tenant: {
      id: null,
      name: "",
      is_active: 0,
      is_trail: 0,
      start_date: "",
      end_date: "",
      note: "",
      title: "",
      main_color: null,
      secondary_color: null,
      logo_sm: null,
      logo_bg: null,
      message: "",
      address: "",
      phone: "",
      working_hour: null,
      working_day: null,
      instance: null
    }
  },
  resetPasswordByAdmin: {
    dialog: false,
    user_id: null,
    form: {
      password: "",
      new_password: ""
    },
  },
  loginHistory: {
    userId: null,
    dialog: false,
    data: [],
  },
  UserRoles: [
    { name: "مدير", key: 'super_admin' },
    { name: "سكرتير", key: 'secretary' },
    { name: "موظف", key: 'staff' },
    { name: "مستخدم", key: 'user' },
    { name: "طبيب", key: 'dentist' },
  ]
}

export const usersSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setDialog: (state, action) => {
      state.user.dialog = !state.user.dialog
    },
    setResetPasswordByAdminDialog: (state, {
      payload
    }) => {
      state.resetPasswordByAdmin.dialog = !state.resetPasswordByAdmin.dialog
      state.resetPasswordByAdmin.user_id = payload
    },
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    setById: (state, {
      payload
    }) => {
      state.user.form.id = payload.id
      state.user.form.user_name = payload.user_name
      state.user.form.full_name = payload.full_name
      state.user.form.email = payload.email
      state.user.form.job_title = payload.job_title
      state.user.form.role = payload.roles
      state.user.form.birth_date = payload.birth_date ? payload.birth_date : null
    },
    setDataTable: (state, {
      payload
    }) => {
      state.users.data = payload.data.data
      state.users.total = payload.data.total
    },
    setDataList: (state, {
      payload
    }) => {
      state.usersList.data = payload.data.data
    },
    setDoctorData: (state, {
      payload
    }) => {
      state.doctorList = payload.data.data
    },
    resetForm: (state, action) => {
      state.user.form = initialState.user.form
    },
    setUserInfo: (state, {
      payload
    }) => {
      state.userInfo.id = payload.id
      state.userInfo.birth_date = payload.birth_date
      state.userInfo.email = payload.email
      state.userInfo.full_name = payload.full_name
      state.userInfo.job_title = payload.job_title
      state.userInfo.role = payload.roles
      state.userInfo.user_name = payload.user_name
      state.userInfo.tenant = payload.tenant
    },
    setUserInfoLoading: (state, action) => {
      state.userInfoLoading = !state.userInfoLoading;
    },
    setRole: (state, {
      payload
    }) => {
      state.role = payload
    },
    setSearchBody: (state, {
      payload
    }) => {
      state.searchBody = payload;
    },
    setLoginHistoryDialog: (state, {
      payload
    }) => {
      state.loginHistory.dialog = !state.loginHistory.dialog;
      state.loginHistory.userId = payload
    },
    setLoginHistory: (state, {
      payload
    }) => {
      state.loginHistory.data = payload.data.data;
    },
  },
})

export const {
  setDialog,
  setById,
  setLoading,
  setDataTable,
  setErrors,
  setUser,
  resetForm,
  setUserInfo,
  setRole,
  setSearchBody,
  setDataList,
  setResetPasswordByAdminDialog,
  setLoginHistoryDialog,
  setLoginHistory,
  setUserInfoLoading,
  setDoctorData
} = usersSlice.actions

export default usersSlice.reducer;


//axios 
const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading('user'));
    const res = await userApi.getAll(params)
    dispatch(setDataTable(res));
    dispatch(setLoading('user'));

  } catch (err) {
    dispatch(setLoading('user'));
    throw new Error(err);
  }
};
const getAllDoctor = (params) => async (dispatch) => {
  try {
    dispatch(setLoading('user'));
    const res = await userApi.getAll(params)
    dispatch(setDoctorData(res));
    dispatch(setLoading('user'));
  } catch (err) {
    dispatch(setLoading('user'));
    throw new Error(err);
  }
};
const getAllList = (params) => async (dispatch) => {
  try {
    dispatch(setLoading('user'));
    const res = await userApi.getAllList(params)
    dispatch(setDataList(res));
    dispatch(setLoading('user'));

  } catch (err) {
    dispatch(setLoading('user'));
    throw new Error(err);
  }
};

const getById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading('user'));
    const res = await userApi.getById(id)
    dispatch(setById(res.data.data[0]));
    dispatch(setDialog())
    dispatch(setLoading('user'));
  } catch (err) {
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    dispatch(setLoading('user'));
    throw new Error(err);
  }
};
const getByIdData = (id) => async (dispatch) => {
  try {
    dispatch(setLoading('user'));
    const res = await userApi.getById(id)
    dispatch(setById(res.data.data[0]));

    dispatch(setLoading('user'));
  } catch (err) {
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    dispatch(setLoading('user'));
    throw new Error(err);
  }
};

const login = (data) => async (dispatch) => {
  try {
    // dispatch(setLoading('users'));
    const res = await userApi.login(data)
    return Promise.resolve(res)
    // dispatch(setDataTable(res));
    // dispatch(setLoading('users'));

  } catch (err) {
    // dispatch(setLoading('users'));
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};

const getIp = (data) => async (dispatch) => {
  try {
    const res = await userApi.getIp(data)
    return Promise.resolve(res)
  } catch (err) {
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const create = (data) => async (dispatch) => {
  try {
    await userApi.create(data)
    dispatch(showNotification({
      message: "تمت الاضافة  بنجاح",
      type: "success"
    }));
    dispatch(setDialog())
    dispatch(getAll())
  } catch (err) {
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const deleteById = (id, _SearchBody) => async (dispatch) => {
  try {
    await userApi.delete(id)
    dispatch(showNotification({
      message: "تم الحذف بنجاح",
      type: "success"
    }));
    dispatch(getAll(_SearchBody))
  } catch (err) {
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const update = (id, data, _SearchBody) => async (dispatch) => {
  try {
    await userApi.update(id, data)
    dispatch(showNotification({
      message: "تم التعديل بنجاح",
      type: "success"
    }));
    dispatch(setDialog())
    dispatch(getAll(_SearchBody))
  } catch (err) {
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};

const userInfo = () => async (dispatch) => {
  try {
    dispatch(setUserInfoLoading())
    const res = await userApi.userInfo()
    dispatch(setUserInfo(res.data.data[0]));
    localStorage.setItem('role', res.data.data[0]?.roles[0]?.name)
    dispatch(setUserInfoLoading())
  } catch (err) {
    dispatch(setUserInfoLoading())
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const updatePassowrd = (data) => async (dispatch) => {
  dispatch(setLoading('user'));
  try {
    await userApi.changePassword(data)
    dispatch(setLoading('user'));
    dispatch(showNotification({
      message: "تم التعديل بنجاح",
      type: "success"
    }));
  } catch (err) {
    dispatch(setLoading('user'));
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const resetPasswordByAdmin = (data) => async (dispatch) => {
  dispatch(setLoading('user'));
  try {
    await userApi.resetPasswordByAdmin(data)
    dispatch(setLoading('user'));
    dispatch(setResetPasswordByAdminDialog(null))
    dispatch(showNotification({
      message: "تم التعديل بنجاح",
      type: "success"
    }));
  } catch (err) {
    dispatch(setLoading('user'));
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const getLoginHistory = (userId) => async (dispatch) => {
  try {
    dispatch(setLoading('user'));
    const res = await userApi.loginHistory(userId);
    dispatch(setLoginHistory(res));
    dispatch(setLoading('user'));

  } catch (err) {
    dispatch(setLoading('user'));
    throw new Error(err);
  }
};




export const User = {
  getAll,
  getAllDoctor,
  login,
  getById,
  update,
  create,
  deleteById,
  userInfo,
  updatePassowrd,
  getByIdData,
  getIp,
  getAllList,
  resetPasswordByAdmin,
  getLoginHistory
}