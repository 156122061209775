import { useState } from "react";
import { Menu, MenuItem, Chip, Typography, ListItemText } from "@mui/material";

export function ChangeAppointmentStatus(props) {
  const [anchorEl, setAnchorEl] = useState(null);

  const _JOrderStatus = [
    { name: "تم الحجز", id: 1 },
    { name: "جاري العمل", id: 2 },
    { name: "تم الانجاز", id: 3 },
    { name: "الغاء", id: 4 },
  ];

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const filteredStatus = _JOrderStatus.filter(
    (item) => item.name === props.name
  )[0];

  return (
    <>
      <Menu
        id={`actions-${props.id}`}
        anchorEl={anchorEl}
        keepMounted
        disableAutoFocusItem
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {_JOrderStatus.map((item, i) => (
          <MenuItem
            key={i}
            onClick={() => {
              props.onChangeStatus(item);
              handleClose();
            }}
          >
            <ListItemText primary={item.name} />
          </MenuItem>
        ))}
      </Menu>

      <Chip
        variant="contained"
        size="small"
        color={
          props.name === "تم الحجز"
            ? "info"
            : props.name === "جاري العمل"
              ? "warning"
              : props.name === "تم الانجاز"
                ? "success"
                : "error"
        }
        label={
          <Typography fontSize={"small"}>{filteredStatus?.name}</Typography>
        }
        onClick={(e) => {
          handleMenuClick(e);
        }}
      />
    </>
  );
}
